<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="getImgUrl(currentLocale.code)"
        height="14px"
        width="22px"
        :alt="currentLocale.code"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="lang in languages"
      :key="lang.code"
      @click="setLocal(lang)"
    >
      <b-img
        :src="getImgUrl(lang.code)"
        height="14px"
        width="22px"
        :alt="lang.code"
      />
      <span class="ml-50">{{ lang.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import { localize } from "vee-validate";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.languages.find((lang) => lang.code === this.$i18n.locale);
    },
  },

  methods: {
    getImgUrl(filename) {
      try {
        return require(`@/assets/images/flags/${filename}.png`);
      } catch (_) {
        return require(`@/assets/images/flags/un.png`);
      }
    },
    async setLocal(lang) {
      this.$i18n.locale = lang.code;
      localize(lang.code);
    },
    async activeLanguage() {
      try {
        const res = await instance.get(`/parameters/languages/active/`);
        this.languages = res.data;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    async getpath() {
      try {
        const res = await instance.get(
          `/parameters/languages/get_language_path/`
        );
        this.path = res.data[0].path;
        sessionStorage.setItem("path", this.path);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  created() {
    this.activeLanguage();
    this.getpath();
  },
  data() {
    return {
      languages: [],
    };
  },
  setup() {},
};
</script>

<style></style>
