export default [
  {
    header: "Users",
    resource: "admin",
    action: "all",
  },
  {
    title: "Managing Users",
    icon: "UsersIcon",
    route: "manage-users",
    resource: "admin",
    action: "all",
    children: [
      /*{
        title: "Permissions",
        route: "permissions",
        resource: "admin",
        action: "all",
      },*/
      {
        title: "Roles",
        route: "roles",
        resource: "admin",
        action: "all",
      },
      {
        title: "Users",
        route: "users",
        resource: "admin",
        action: "all",
      },
    ],
  },
  {
    header: "Settings",
    resource: "admin",
    action: "all",
  },
  {
    title: "Products Settings",
    icon: "PackageIcon",
    route: "products",
    resource: "admin",
    action: "all",
    children: [
      {
        title: "Product Names",
        route: "productname",
        resource: "admin",
        action: "all",
      },
      {
        title: "Tranches",
        route: "tranches",
        resource: "admin",
        action: "all",
      },
      {
        title: "Packaging",
        route: "packaging",
        resource: "admin",
        action: "all",
      },
      {
        title: "Categories",
        route: "categories",
        resource: "admin",
        action: "all",
      },
      {
        title: "Glazaurage",
        route: "glazaurage",
        resource: "admin",
        action: "all",
      },
      {
        title: "Unitites",
        route: "unitites",
        resource: "admin",
        action: "all",
      },
      {
        title: "Selling Types",
        route: "sellingtypes",
        resource: "admin",
        action: "all",
      },
      {
        title: "Brands",
        route: "brands",
        resource: "admin",
        action: "all",
      },
      {
        title: "Packing",
        route: "packing",
        resource: "admin",
        action: "all",
      },
      {
        title: "Product Combination",
        route: "productcombination",
        resource: "admin",
        action: "all",
      },
      {
        title: "Preparations",
        route: "preparations",
        resource: "admin",
        action: "all",
      },
      {
        title: "VATs",
        route: "vats",
        resource: "admin",
        action: "all",
      },
    ],
  },

  {
    title: "Clients Settings",
    icon: "UsersIcon",
    route: "clients",
    resource: "admin",
    action: "all",
    children: [
      {
        title: "Clients",
        route: "clients",
        resource: "admin",
        action: "all",
      },
      {
        title: "Clients Annexes",
        route: "clientsannexes",
        resource: "admin",
        action: "all",
      },
      {
        title: "Profiles",
        route: "clientprofiles",
        resource: "admin",
        action: "all",
      },
      {
        title: "Sub Profiles",
        route: "clientsubprofiles",
        resource: "admin",
        action: "all",
      },
    ],
  },
  {
    title: "Stocks Settings",
    icon: "LayersIcon",
    route: "stocklots",
    resource: "admin",
    action: "all",

    children: [
      // {
      //   title: "Lot",
      //   route: "stocklots",
      // },
      {
        title: "Physical Depot",
        route: "physicaldepot",
        resource: "admin",
        action: "all",
      },
      {
        title: "Depot",
        route: "depot",
        resource: "admin",
        action: "all",
      },
    ],
  },
  {
    title: "Delivery Settings",
    icon: "ArchiveIcon",
    route: "cities",
    resource: "admin",
    action: "all",
    children: [
      /*{
        title: "Cities",
        route: "cities",
        resource: "admin",
        action: "all",
      },
      {
        title: "City Areas",
        route: "cityareas",
        resource: "admin",
        action: "all",
      },*/
      {
        title: "Shipping Types",
        route: "shippingtypes",
        resource: "admin",
        action: "all",
      },
      /*{
        title: "Docks",
        route: "docks",
        resource: "admin",
        action: "all",
      },
      {
        title: "Vehicles",
        route: "vehicles",
        resource: "admin",
        action: "all",
      },*/
    ],
  },
  {
    title: "Order Settings",
    icon: "ClipboardIcon",
    route: "ordersources",
    resource: "admin",
    action: "all",
    children: [
      {
        title: "Order Sources",
        route: "ordersources",
        resource: "admin",
        action: "all",
      },
      {
        title: "Order Status",
        route: "orderstates",
        resource: "admin",
        action: "all",
      },
      {
        title: "Emergency Degrees",
        route: "emergencydegrees",
        resource: "admin",
        action: "all",
      },
    ],
  },

  {
    title: "Global Settings",
    icon: "LayersIcon",
    route: "stocklots",
    resource: "admin",
    action: "all",

    children: [
      /*{
        title: "Wording",
        route: "wordings",
        resource: "admin",
        action: "all",
      },*/
      {
        title: "Language",
        route: "languages",
        resource: "admin",
        action: "all",
      },
      {
        title: "Path",
        route: "path",
        resource: "admin",
        action: "all",
      },
    ],
  },

  /*{
    header: "Traceability",
    resource: "admin",
    action: "all",
  },

  {
    title: "Traceability",
    icon: "SearchIcon",
    route: "designationpricehistory",
    resource: "admin",
    action: "all",
    children: [
      {
        title: "Designation price",
        route: "designationpricehistory",
        resource: "admin",
        action: "all",
      },
    ],
  },*/
  /*{
    header: "Sale",
    resource: "sales_department",
    action: "all",
  },
  {
    title: "Designation",
    icon: "DollarSignIcon",
    route: "designation",
    resource: "sales_department",
    action: "all",
  },
  {
    header: "Quotation",
    resource: "sales_department",
    action: "all",
  },
  {
    title: "Quotation",
    icon: "CreditCardIcon",
    route: "addquotation",
    resource: "sales_department",
    action: "all",
    children: [
      {
        title: "Add quotation",
        route: "addquotation",
        resource: "sales_department",
        action: "all",
      },
      {
        title: "Quotation List",
        route: "quotations",
        resource: "sales_department",
        action: "all",
      },
    ],
  },
  {
    header: "Recoverer",
    resource: "recoverer",
    action: "all",
  },
  {
    title: "Recovery",
    icon: "DollarSignIcon",
    route: "recovery",
    resource: "recoverer",
    action: "all",
  },
  {
    title: "Recovery",
    icon: "DollarSignIcon",
    route: "validaterecovery",
    resource: "recoverer",
    action: "all",
  },
  {
    title: "Visit",
    icon: "DollarSignIcon",
    route: "visits",
    resource: "recoverer",
    action: "all",
  },
  {
    header: "Situation",
    resource: "admin",
    action: "all",
  },
  {
    title: "Situation",
    icon: "DollarSignIcon",
    route: "situations",
    resource: "admin",
    action: "all",
    children: [
      {
        title: "Add Situation",
        route: "addsituations",
        resource: "admin",
        action: "all",
      },
      {
        title: "Situation List",
        route: "situations",
        resource: "admin",
        action: "all",
      },
      {
        title: "Global Situation List",
        route: "globalsituations",
        resource: "admin",
        action: "all",
      },
    ],
  },*/
  {
    header: "Operator",
    resource: "operator",
    action: "all",
  },

  {
    title: "Orders",
    icon: "ClipboardIcon",
    route: "orders",
    resource: "operator",
    action: "all",
    children: [
      /*{
        title: "Add Order",
        route: "addorder",
        resource: "operator",
        action: "all",
      },*/
      {
        title: "Add Order",
        route: "sellerorder",
        resource: "operator",
        action: "all",
      },
      {
        title: "Order List",
        route: "orderList",
        resource: "operator",
        action: "all",
      },
    ],
  },
  {
    header: "input",
    resource: "input",
    action: "all",
  },
  {
    title: "Delivery Note",
    icon: "ClipboardIcon",
    route: "deliverynote",
    resource: "input",
    action: "all",
  },
  {
    header: "Orders (Managment)",
    resource: "operation_managment",
    action: "all",
  },
  {
    title: "Order Tracking",
    icon: "ClipboardIcon",
    route: "managerorderstracking",
    resource: "operation_managment",
    action: "all",
  },
  /*{
    title: "Charge Tracking",
    icon: "TruckIcon",
    route: "chargetracking",
    resource: "operation_managment",
    action: "all",
  },*/
  {
    title: "Order Line Tracking",
    icon: "TruckIcon",
    route: "orderlinetracking",
    resource: "operation_managment",
    action: "all",
  },
  {
    header: "Orders (Storehead)",
    resource: "storehead",
    action: "all",
  },
  {
    title: "Order Tracking",
    icon: "ClipboardIcon",
    route: "storeheadorderstracking",
    resource: "storehead",
    action: "all",
  },
  {
    header: "Orders (Storekeeper)",
    resource: "storekeeper",
    action: "all",
  },
  {
    title: "Order Tracking",
    icon: "ClipboardIcon",
    route: "storekeeperorderstracking",
    resource: "storekeeper",
    action: "all",
  },
  {
    header: "Orders (Delivery)",
    resource: "delivery",
    action: "all",
  },
  {
    title: "Order Assignment",
    icon: "LayersIcon",
    route: "OrderAffectation",
    resource: "delivery",
    action: "all",
  },
  {
    title: "Order Tracking",
    icon: "ClipboardIcon",
    route: "deliveryorderstracking",
    resource: "delivery",
    action: "all",
  },
  {
    title: "Order Return",
    icon: "CornerDownLeftIcon",
    route: "deliveryreturntracking",
    resource: "delivery",
    action: "all",
  },
  {
    header: "Orders (Seller)",
    resource: "seller",
    action: "all",
  },
  {
    title: "Order Tracking",
    icon: "ClipboardIcon",
    route: "sellerordertracking",
    resource: "seller",
    action: "all",
  },
];
