<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        v-if="count > 0"
        :badge="count"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
      <feather-icon
        v-else
        badge="0"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t("Notifications") }}
        </h4>
        <b-badge pill variant="light-white">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            @click="MarkAllNotificationAsRead()"
          >
            {{ $t("Mark all as read") }}
          </b-button>
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="settings"
      class="scrollable-container media-list scroll-area"
    >
      <!-- Account Notification -->
      <b-link v-for="notification in UserNotifications" :key="notification.id">
        <b-media
          v-if="AsNotificationViewed.includes(notification.id) === true"
          style="cursor: default"
          class="notification_color"
        >
          <template #aside>
            <b-avatar
              v-if="AsNotificationViewed.includes(notification.id) === true"
              size="32"
              variant="light-white"
              :src="require('@/assets/images/logo/icon-bell.png')"
              badge
              class="badge-minimal"
              badge-variant="primary"
            />
            <b-avatar
              v-else
              size="32"
              variant="light-white"
              :src="require('@/assets/images/logo/icon-bell.png')"
            />
          </template>
          <p
            class="media-heading"
            v-if="notification.state.codename === 'received'"
          >
            <span class="font-weight-bolder">
              <!----La ligne de commande de référence-->
              {{ $t("An Order has been placed !") }}
            </span>
          </p>
          <p class="media-heading" v-else>
            <span class="font-weight-bolder">
              <!----La ligne de commande de référence-->
              {{ $t("OrderLine status changed !") }}
            </span>
          </p>

          <small
            class="notification-text"
            v-if="notification.state.codename === 'received'"
          >
            {{ $t("Reference order is") + " : " + notification.ref }}<br />
            {{ $t("Order status is") + " : " + notification.state.name }}
          </small>
          <small class="notification-text" v-else>
            {{ $t("Reference order is") + " : " + notification.ref }}<br />
            {{
              $t("OrderLine status change to") + " : " + notification.state.name
            }}</small
          >
          <b-button
            variant="primary"
            class="btn-icon notification_button"
            @click="viewedNotification(notification)"
          >
            <feather-icon icon="EyeIcon" size="16" class="position" />
            <!--Affect-->
          </b-button>
        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">System Notifications</h6>
        <b-form-checkbox :checked="true" switch />
      </div> -->

      <!-- System Notifications -->
      <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar size="32" :variant="notification.type">
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer 
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="ReadAllNotification()"
        >{{ $t("Load more") }}</b-button
      >
    </li>-->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import firebase from "firebase/compat/app";
import "firebase/compat/app";
import "firebase/compat/messaging";
import $ from "jquery";
//import Push from "push.js";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  Notifications: {
    type: Object,
    //required: true,
  },
  data() {
    return {
      Notifications: [],
      viewed: [],
      userRole: null,
      settings: {
        maxScrollbarLength: 60,
      },
      count: null,

      AsNotificationViewed: [],
      UserNotifications: [],
    };
  },
  created() {
    this.getNotifications();
  },
  methods: {
    /*ReadAllNotification() {
      (this.min = 0), (this.max = this.UserNotifications.length);
    },*/
    async MarkAllNotificationAsRead() {
      try {
        const res = await instance.post(`users/notification/MarkAllAsRead`);
        this.getNotifications();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async getNotifications() {
      // get user role
      const userAuth = await instance.get(`users/notification/getUserAuth`);
      this.userRole = userAuth.data.role;
      var user_id = userAuth.data.user_id;
      const notifs = [];
      // get notification by role
      for (let i = 0; i < this.userRole.length; i++) {
        const role = this.userRole[i];
        const res = await instance.get(`users/notification/`, {
          params: { role: role },
        });
        this.Notifications = res.data;
        // push notification in array
        this.Notifications.forEach((notification) => {
          notifs.push({
            id: notification.id,
            ref: notification.ref,
            state: notification.state,
            management_agent_id: notification.management_agent_id,
            storekeeper_agent_id: notification.storekeeper_agent_id,
            delivery_agent_id: notification.delivery_agent_id,
            viewed: notification.viewed,
          });
        });
      }

      // remove duplicate notification
      var notification = notifs.filter(
        (notifs, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.ref === notifs.ref && t.state.codename === notifs.state.codename
          )
      );

      this.UserNotifications = notification;
      // sorting array by notification id
      this.UserNotifications.sort((a, b) => Number(b.id) - Number(a.id));
      // count notification number by role
      this.viewed = [];
      this.UserNotifications.forEach((notification) => {
        if (this.viewed.includes(notification.id) === false) {
          if (notification.viewed === null) {
            this.viewed.push(notification.id);
          } else if (notification.viewed.includes(user_id) === false) {
            this.viewed.push(notification.id);
          }
        }
      });

      this.AsNotificationViewed = [];
      this.count = this.viewed.length;
      this.AsNotificationViewed = this.viewed;
    },
    async viewedNotification(notification) {
      try {
        const res = await instance.post(`users/notification/updateView`, {
          id: notification.id,
        });
        this.getNotifications();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    // push notification
    async saveNotificationToken(token) {
      const payload = {
        registration_id: token,
        type: "web",
      };
      await instance
        .post("/notification_token/", {
          payload: payload,
        })
        .then((response) => {
        })
        .catch((error) => {
          if (error.response) {
            // Most of the time a "this field must be unique" error will be returned,
            // meaning that the token already exists in db, which is good.
            if (error.response.data.registration_id) {
              for (let err of error.response.data.registration_id) {
              }
            } else {
            }
            // If the request could not be sent because of a network error for example
          } else if (error.request) {
            // For any other kind of error
          } else {
          }
        });
    },
  },
  mounted() {
    // firebase config
    var firebaseConfig = {
      apiKey: "AIzaSyB7FS--drpNg9EQEpk2b9-w3JR9bDYde-U",
      authDomain: "logistique-8385d.firebaseapp.com",
      projectId: "logistique-8385d",
      storageBucket: "logistique-8385d.appspot.com",
      messagingSenderId: "315092983219",
      appId: "1:315092983219:web:f92deae580d7b7873bffc6",
      measurementId: "G-J45LT010K5",
    };
    firebase.initializeApp(firebaseConfig);

    const messaging = firebase.messaging();
    // get token
    messaging.getToken(
      "AAAASV0BXbM:APA91bF5p51iDv4hl9R301r1RGrGKYVjYC4Qy1LN6KMTuTkcOEO_isMpECsOMmg31LtIrADRNT_el4x3mIeZF1xqBLZiHBO1skdcSoKIiMc2DvLv2c-UUb2FqcTezXPn2zOwNl3voMAn"
    );
    messaging.getToken().then((token) => {
      this.saveNotificationToken(token);
    });

    messaging.onMessage(async (payload) => {
      const title = payload.notification.title;
      const options = {
        body: payload.notification.body,
        icon: payload.notification.icon,
        vibrate: [200, 100, 200, 100, 200, 100, 200],
        tag: "vibration-sample",
      };
      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification(title, options);
        this.getNotifications();
      });
    });
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("firebase-messaging-sw.js")
        .then((reg) => {
        })
        .catch((error) => {
          const msg = `Service Worker Error (${error})`;
        });
    } else {
      // happens when the app isn't served over HTTPS or if the browser doesn't support service workers
      console.warn("Service Worker not available");
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 2,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style>
.notification_button {
  position: absolute;
  right: 24px;
  margin-top: -20px;
  height: 25px;
  width: 25px;
}
svg.position.feather.feather-eye {
  display: flex;
  margin-left: -7px;
  margin-top: -6px;
}
/*
:class="{
            isRead: AsNotificationViewed.includes(notification.id) === true,
          }"
*/
</style>
